import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from '../../styles';
import FullscreenDialog from '../FullscreenDialog';
import ScrollableViews from '../ScrollableViews';
import Img from '../Img';

const useClasses = createClasses((theme) => ({
	root: {},
	carousel: {
		height: '100%',
		width: '100%'
	},
	carouselContainer: {
		height: '100%',
		width: '100%'
	},
	carouselCardContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	navDot: {
		padding: theme.spacing(1),
		'&:after': {
			backgroundColor: 'rgba(0, 0, 0, .4)'
		}
	},
	navDots: {
		position: 'absolute',
		bottom: theme.spacing(2),
		[theme.config.mediaQueries.touch]: {
			display: 'none'
		}
	},
	activeNavDot: {
		'&:after': {
			backgroundColor: theme.config.palette.common.white
		}
	},
	image: {
		display: 'flex',
		margin: 'auto',
		flexDirection: 'column',
		alignItems: 'center'
	}
}), {
	name: 'RaImgCarouselDialog'
});

function ImgCarouselDialog(props) {
	const {
		classes: classesProp,
		className,
		images: imagesProp = [],
		ImgProps,
		ImgComponent = Img,
		CarouselProps,
		defaultCursor,
		FullscreenDialogComponent = FullscreenDialog,
		...rest
	} = props;

	const classes = useClasses(props);

	const images = (
		imagesProp.map((image, key) => {
			const { title, imageSrc, imgProps, width, height } = image || {};

			return (
				<ImgComponent
					alt={title}
					src={imageSrc}
					caption={title}
					showPlaceholder
					{...ImgProps}
					key={key}
					fullScreen
					className={classes.image}
					width={width}
					height={height}
					imgProps={imgProps}
					loadSync={false}
					loadingDelay={250}
				/>
			);
		})
	);

	const CarouselClasses = React.useMemo(() => ({
		container: classes.carouselContainer,
		slide: classes.carouselCardContainer,
		navDot: classes.navDot,
		activeNavDot: classes.activeNavDot,
		navDots: classes.navDots,
		...CarouselProps?.classes
	}), [
		classes,
		CarouselProps
	]);

	return (
		<FullscreenDialogComponent
			delay={0}
			noAppBar
			{...rest}
			className={classes.root}
		>
			<ScrollableViews
				fullWidth
				{...CarouselProps}
				defaultCursor={defaultCursor}
				className={classes.carousel}
				classes={CarouselClasses}
			>
				{images}
			</ScrollableViews>
		</FullscreenDialogComponent>
	);
}

ImgCarouselDialog.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	ImgProps: PropTypes.object,
	CarouselProps: PropTypes.object,
	FullscreenDialogComponent: PropTypes.elementType,
	ImgComponent: PropTypes.elementType
};

export default React.memo(ImgCarouselDialog);
